<script>
import { geoEventPage } from '@/api/base'
import CalTableHeight from '@/mixins/calTableHeight'

export default {
  data() {
    return {
      // Loading
      listLoading: false,
      // 电子围栏数据
      dataList: [],
      total: 0,
      // 模糊查询请求参数`
      queryParams: {
        name: '', // 项目/消纳场名称
        type: '', // 类型 0 工地，1消纳场
        fleetName: '', // 运输单位
        plateNumber: '', // 车牌号码
        startTimeStr: '', // 开始时间
        endTimeStr: '', // 结束时间
        currentPage: 1,
        pageSize: 10 //每页大小
      }
    }
  },
  created() {
    this.queryList()
  },
  mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
  },
  methods: {
    async queryList() {
      try {
        this.listLoading = true
        const { data } = await geoEventPage(this.queryParams)
        console.log(data)
        this.dataList = data.records
        this.total = data.total
      } catch (error) {
        console.log(error)
      } finally {
        this.listLoading = false
      }
    }
  },
  mixins: [CalTableHeight]
}
</script>

<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">围栏触发记录</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()" size="small">
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="项目/消纳场名称:">
                <el-input
                  v-model.trim="queryParams.name"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                  @clear="queryList()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="类型:">
                <el-select class="input-width-6" v-model="queryParams.type" clearable>
                  <el-option :value="0" label="工地"></el-option>
                  <el-option :value="1" label="消纳场"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="运输单位:">
                <el-input
                  v-model.trim="queryParams.fleetName"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                  @clear="queryList()"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="6">
              <el-form-item label="车牌号码:">
                <el-input
                  v-model.trim="queryParams.plateNumber"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                  @clear="queryList()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="开始时间:">
                <el-date-picker
                  type="datetime"
                  class="input-width-6"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="queryParams.startTimeStr"
                  placeholder="选择日期时间"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结束时间:">
                <el-date-picker
                  type="datetime"
                  class="input-width-6"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="queryParams.endTimeStr"
                  placeholder="选择日期时间"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.currentPage"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.pageSize"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            show-footer-overflow="tooltip"
            show-header-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
            <vxe-table-column field="name" title="项目/消纳场名称" align="center" width="200" />
            <vxe-table-column field="name" title="类型" align="center" width="200">
              <template v-slot="{ row }">
                {{ row.type === 1 ? '消纳场' : '工地' }}
              </template>
            </vxe-table-column>
            <vxe-table-column field="fleetName" title="运输单位" align="center" width="200" />
            <vxe-table-column field="plateNumber" title="车牌号码" align="center" width="200" />
            <vxe-table-column field="startTimeStr" title="开始时间" align="center" width="200" />
            <vxe-table-column field="endTimeStr" title="结束时间" align="center" width="200" />
            <vxe-table-column align="center" fixed="right" title="操作" width="150">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer">查看轨迹</span>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}

.icon-cls-green {
  color: #67c23a;
}

.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section {
  height: 600px;
}

.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}

.el-pagination {
  float: right;
}

.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}

.vxe-table--footer .col-blue {
  background: #f2f5f0;
}

/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}

.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}

.write-color {
  border-color: #f0f0f0;
}

/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}

.input-width-7 {
  width: 70% !important;
}

.input-width-8 {
  width: 80% !important;
}

.input-width-16 {
  width: 300px;
}
</style>
